<template>
  <div class="login">
    <div class="container login-wrapper">
      <div class="row align-items-center">
        <div class="col-md-5">
          <img src="../assets/login-img.svg" alt="" />
        </div>
        <div class="col-md-7">
          <h3>Reset Password</h3>
          <form @submit.prevent="resetPassword">
            <div class="mb-3">
              <label for="exampleInputEmail1" class="form-lab el"
                >Password</label
              >
              <input
                type="password"
                class="form-control"
                id="password"
                v-model="password"
                aria-describedby="emailHelp"
              />
              <div
                class="input-errors"
                v-for="(error, errorIndex) of v$.password.$errors"
                :key="errorIndex"
              >
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>
            <div class="mb-3">
              <label for="exampleInputEmail1" class="form-lab el"
                >Confirm Password</label
              >
              <input
                type="password"
                class="form-control"
                id="password"
                v-model="confirmPassword"
                aria-describedby="emailHelp"
              />
              <div
                class="input-errors"
                v-for="(error, errorIndex) of v$.confirmPassword.$errors"
                :key="errorIndex"
              >
                <div class="error-msg">{{ error.$message }}</div>
              </div>
              <div v-if="errors && errors.password" class="error-msg">
                {{ errors.password[0] }}
              </div>
            </div>
            <div class="text-right">
              <button
                type="submit"
                :disabled="isAuthenticating == true"
                class="btn cta-primary"
              >
                <span v-if="isAuthenticating == false">
                  Reset
                </span>
                <span v-else>
                  <circular-loader></circular-loader>
                </span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import AuthService from "@/services/AuthService";
import CircularLoader from "@/components/loaders/CircularLoader";
import "bootstrap";
import { required, helpers, sameAs } from "@vuelidate/validators";
export default {
  setup() {
    return {
      v$: useVuelidate()
    };
  },
  name: "Login",
  components: {
    CircularLoader
  },
  data() {
    return {
      password: "",
      confirmPassword: "",
      id: this.$route.query.id,
      expired_at: this.$route.query.e,
      isAuthenticating: false,
      errors: {}
    };
  },
  validations() {
    return {
      password: {
        required: helpers.withMessage("Please enter password", required)
      },
      confirmPassword: {
        required: helpers.withMessage("Please Confirm password", required),
        sameAsPassword: helpers.withMessage(
          "Confirm password not match",
          sameAs(this.password)
        )
      }
    };
  },
  methods: {
    resetPassword: function() {
      this.v$.$touch();
      if (this.v$.$invalid) {
        return;
      }
      this.isAuthenticating = true;
      const data = {
        password: this.password,
        password_confirmation: this.confirmPassword,
        id: this.id,
        expired_at: this.expired_at
      };
      AuthService.resetPassword(data)
        .then(result => {
          this.isAuthenticating = false;
          if (result.data.status == "success") {
            this.$toast.success(result.data.message);
            this.$router.push({ name: "login" });
          } else if (this.authStatus == "validation_error") {
            this.errors = result.data.message || {};
          } else {
            this.$toast.error(result.data.message);
          }
        })
        .catch(error => {
          this.error = error;

          this.isAuthenticating = true;
        });
    }
  }
};
</script>

<style lang="scss">
@import "@/style/login.scss";
</style>
